.select_evento{
    height: 50px;
    float: left;
}
.sel_evento{
    height: 40px;
}
.select_funcionario{
    height: 50px;
    float: left;
    margin-left: 50px;
    margin-right: 50px;
    /* width: 250px !important; */
}

.sel_funcionario{
    height: 40px;
    width: 550px !important;
}
.titu{
    height: 60px;
}