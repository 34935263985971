.logo-pdv {
    width: 130px !important;
    height: 120px !important;
    padding: 3px;
    margin-top: -13px;
    margin-bottom: 19px;
  }
  
  .login-page {
    width: 360px;
    padding: 9% 0 0;
    margin: auto;
  }
  input{
    border: 2px solid gray !important;
    border-radius: 7px;
  }
  select{
    border: 2px solid gray !important;
    border-radius: 7px;
    color: gray;
  }
  
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    margin-top: -60px;
  }
  
  .form input {
    font-family: "Roboto", sans-serif;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .form select {
    font-family: "Roboto", sans-serif;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    background: gray;
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    border-radius: 7px;
  }
  
  body {
    /* background: rgb(255, 197, 196);
    background: rgb(255, 197, 196);
    background: linear-gradient(90deg, rgba(255, 197, 196) 0%, rgba(255, 197, 196) 50%); */
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .form-floating {
    margin-top: -10px;
    margin-bottom: 4px;
    height: 60px !important;
  }


.error-message {
  background-color: #f44336; 
  color: white; 
  padding: 10px; 
  border-radius: 5px; 
  margin-top: 10px; 
  text-align: center;
}


.no-error {
  margin-top: 0;
}

.inp_cpf{
  text-align: center;
}