body {
  background-color: #FFCBDB;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containers {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.boasV {
  font-size: 24px;
  margin-bottom: 20px;
}
.numpontos{
  color:#FF69B4 !important;
}
.labelPontos{
  color: #FF69B4 !important;
  margin-left: 98px
}

.circule {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #FF69B4;
  border: 3px solid #FF69B4;
}

.tabela_funcionarios {
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px; /* Espaçamento maior da tabela para não encostar no círculo */
}

.tabela_funcionarios th, .tabela_funcionarios td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.tabela_funcionarios th {
  background-color: #FF69B4;
  color: white;
}



@media only screen and (max-width: 600px) {
  .containers {
    width: 95%;
  }

  .circule {
    margin: 10px 0 20px; /* Ajuste de margens para dispositivos menores */
  }
}


.linha-negativa td {
  color: black !important;
  background-color: pink !important;
}
