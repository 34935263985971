.select_funcionario2 {
    width: 40%;
    height: 35px;
}

.motivo_container {
    /* float: left !important; */
    /* margin-left: -20px; */
    /* margin-top: 20px; */
    height: 90px !important;
}

.valor_container {
    height: 40px;
}

.textarea-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
}

.textarea-wrapper label {
    margin-bottom: 6px;
}
.titulo2{
    height: 60px !important;
    margin-top: 10px;
}