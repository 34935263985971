body {
  margin-top: 50px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #39464e;
  width: 100%;
}

.msb {
  margin-left: 2px;
  width: 200px;
  background-color: #F5F7F9;
  position: fixed;
  left: 0;
  top: 0;
  right: auto;
  min-height: 100%;
  overflow-y: auto;
  white-space: nowrap;
  height: 100%;
  z-index: 1;
  border-right: 1px solid #ddd;
  /* margin-right: 2000px !important; */
}

.navbar-header {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 5px !important;
  background: #fff;
  margin-top: -6px;
}

.brand-name-wrapper {
  margin-top: -15px;
}

.logonav {
  width: 90px;
  height: 80px;
  margin-left: 54px !important;
  margin: 15px;
  /* margin-top: -10px; */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px !important;

}

.custom-button {
  background-color: transparent;
  padding: 8.5px 20px !important;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  width: 150px;
  margin-left: 25px !important;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.custom-button:hover {
  background-color: gray;
  color: #fff;

}

.logonav2 {
  width: 45px !important;
  margin-bottom: 0px !important;
  margin-left: 35px !important;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}