p{
    font-weight: bold;
    float: right;
    font-size: 15px;
    margin-right: 100px;
    /* margin-left: 500px; */
}
.soma{
    /* margin-top: 10px; */
    height: 40px;
}
.inp_historico{
    height: 35px;
    width: 30%;
    margin-bottom: 5px;
}